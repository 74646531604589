.auth-layout-cover-image {
  height: 100vh;
}

@media screen and (max-width: 1024px) {
  .auth-layout-cover-image {
    display: none;
  }
}

@media screen and (max-height: 600px) {
  .auth-layout-cover-image {
    display: none;
  }
}
/* global styles*/
body {
  height: 100vh;
  overflow: hidden;
  margin: 0;
  font-family: "Poppins" !important;
  font-style: normal !important;
}

form .ant-form-item-explain {
  display: none;
}

button {
  font-weight: 600 !important;
  line-height: 16px !important;
}

a {
  color: var(--primary-green);
}

a:visited {
  color: var(--secondary-green);
  border: none;
}

a:hover {
  color: var(--green-60);
  border-bottom: 1px solid var(--green-60);
}

a:focus {
  color: var(--primary-green);
  border-bottom: 2px solid var(--secondary-green);
}

a:active {
  color: var(--primary-green);
  border-bottom: 1px solid var(--primary-green);
}

input {
  background: transparent;
}

input:-webkit-autofill {
  -webkit-background-clip: text;
}

form {
  width: 397px;
  height: fit-content;
}

.auth-flex-container {
  display: flex;
}

.auth-password-visibility-icon {
  margin-top: 6px;
  width: 30px;
  height: 30px;
}

.auth-password-visibility-icon:hover {
  cursor: pointer;
}

.auth-form-submit-button-content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

/* Scroll bar styles. */
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #f4f4f4;
  border-radius: 20px;
}

::-webkit-scrollbar-track:hover {
  background-color: #d9d9d9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5bc1b3;
  border-radius: 20px;
}


/* css variables */
:root {
  --primary-green: #26ad9a;
  --secondary-green: #96c73c;
  --help: #802ef2;
  --help-website: #a284f8;
  --help-details: #ea84f8;
  --white: #ffffff;
  --gray-10: #f4f4f4;
  --gray-20: #d9d9d9;
  --gray-30: #c4c4c4;
  --gray-40: #a8a8a8;
  --gray-50: #8f8f8f;
  --gray-60: #6f6f6f;
  --gray-100: #161616;
  --green-10: #f4fbfa;
  --green-20: #d3eeea;
  --green-60: #5bc1b3;
  --green-100: #188273;
  --green-110: #24ad9a;
  --dark-green: #0a3d36;
  --red-25: #f5d5d0;
  --red-60: #ed7362;
  --red-100: #d95644;
  --soft-yellow: #f5ebc9;
  --yellow: #f4c620;
  --background: #f5faf9;
  --soft-green-gradient: linear-gradient(360deg, #d3eeea 0%, #f5faf9 100%);
  --soft-yellow-gradient: linear-gradient(0deg, #f5ebc9 0%, #faf9f5 100%);
  --soft-red-gradient: linear-gradient(0deg, #f5d5d0 0%, #faf6f5 100%);
  --green-gradient: linear-gradient(180deg, #5bc1b3 0%, #d3eeea 100%);
}

@media screen and (max-width: 420px) {
  form {
    width: 300px
  }
}
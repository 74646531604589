.viewErrorsListContainer {
  margin-top: 30px;
  margin-bottom: 30px;
}

.viewErrorsListItem {
  display: flex;
  margin-bottom: 8px;
}

.viewErrorsListCircle {
  min-width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-top: 6.5px;
  margin-right: 4px;
  background-color: var(--red-100);
}

.viewErrorsListError {
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  color: var(--red-100);
}
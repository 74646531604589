.signInContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow-y: auto;
}

.signInPrimaryTitle {
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  margin-top: calc((100vh - 379px) / 2);
  margin-bottom: 30px;
  color: var(--green-60);
}

.signInInput {
  width: 100%;
  height: 48px;
  padding-left: 14px;
  padding-right: 7px;
}

.signInButton {
  position: relative;
  width: 100%;
  border: none;
}

.signInButton:focus-visible {
  border: 4px solid var(--green-60);
  outline: unset !important;
}

.signInButtonSpinner {
  position: absolute !important;
  font-size: 16px !important;
  top: 3px;
  left: 145px;
  color: var(--white);
}

.signInLinkContainer {
  display: block;
  text-align: end;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
}

.signInErrorMessage {
  display: block;
  margin-top: 30px;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
}

@media screen and (max-width: 420px) {
  .signInButtonSpinner {
    left: 96px;
  }
}
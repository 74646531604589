.completeNewPasswordContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow-y: auto;
}

.completeNewPasswordPrimaryTitle {
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  margin-top: calc((100vh - 330px) / 2);
  margin-bottom: 30px;
  color: var(--green-60);
}

.completeNewPasswordInput {
  width: 100%;
  height: 48px;
  padding-left: 14px;
  padding-right: 7px;
}

.completeNewPasswordButton {
  width: 100%;
  border: none;
}

.completeNewPasswordButton:focus-visible {
  border: 4px solid var(--green-60);
  outline: unset !important;
}

.completeNewPasswordButtonSpinner {
  position: absolute !important;
  font-size: 16px !important;
  top: 3px;
  left: 134px;
  color: var(--white);
}

.completeNewPasswordErrorMessage {
  display: block;
  margin-top: 30px;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
}

.completeNewPasswordReCaptcha {
  margin-bottom: 30px;
}

@media screen and (max-width: 420px) {
  .completeNewPasswordButtonSpinner {
    left: 86px;
  }
}
.selectRegionContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.selectRegionContent {
  display: flex;
  flex-direction: column;
}

.selectRegionTitle {
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  color: var(--green-60);
}

.selectRegionSubtitle {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--gray-40);
}

.selectRegionButtonGroup {
  display: flex;
  gap: 10px;
  margin-top: 30px;
}

.selectRegionButton {
  width: 100px;
}

.selectRegionProceedButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.selectRegionProceedButton {
  height: 30px;
  padding: 0 15px;
}
.signUpContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow-y: auto;
}

.signUpPrimaryTitle {
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  margin-top: calc((100vh - 539px) / 2);
  margin-bottom: 15px;
  color: var(--green-60);
}

.signUpAction {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 45px;
  color: var(--gray-60);
}

.signUpInput {
  width: 100%;
  height: 48px;
  padding-left: 14px;
  padding-right: 7px;
}

.signUpButton {
  width: 100%;
  border: none;
  margin-bottom: 0;
}

.signUpButtonSpinner {
  position: absolute !important;
  font-size: 16px !important;
  top: 3px;
  left: 114px;
  color: var(--white);
}

.signUpButton:focus-visible {
  border: 4px solid var(--green-60);
  outline: unset !important;
}

.signUpErrorMessage {
  display: block;
  margin-top: 30px;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
}

.signUpLegalInfo {
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: var(--gray-40);
  margin-bottom: 0;
}

.signUpLegalInfoCheckbox {
  margin-right: 8px;
}

.signUpLegalInfoCheckbox span span {
  border: 2px solid var(--primary-green) !important;
}

.signUpLegalInfoCheckbox span span::after {
  inset-inline-start: 15.5% !important;
}

@media screen and (max-width: 420px) {
  .signUpButtonSpinner {
    left: 66px;
  }
}
.emailVerificationContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow-y: auto;
}

.emailVerificationPrimaryTitle {
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  margin-top: calc((100vh - 356px) / 2);
  margin-bottom: 15px;
  color: var(--green-60);
}

.emailVerificationSecondaryTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 30px;
  color: var(--gray-60);
}

.emailVerificationInput {
  width: 100%;
  height: 48px;
  padding-left: 14px;
  padding-right: 7px;
}

.emailVerificationButton {
  width: 100%;
  border: none;
}

.emailVerificationButton:focus-visible {
  border: 4px solid var(--green-60);
  outline: unset !important;
}

.emailVerificationButtonSpinner {
  position: absolute !important;
  font-size: 16px !important;
  top: 3px;
  left: 145px;
  color: var(--white);
}

.emailVerificationErrorMessage {
  display: block;
  margin-top: 30px;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
}

.emailVerificationGoBackButton {
  width: 100%;
}

@media screen and (max-width: 420px) {
  .emailVerificationButtonSpinner {
    left: 96px;
  }
}
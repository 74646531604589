.resetPasswordContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow-y: auto;
}

.resetPasswordPrimaryTitle {
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  margin-top: calc((100vh - 510px) / 2);
  margin-bottom: 30px;
  color: var(--green-60);
}

.resetPasswordSecondaryTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 30px;
  color: var(--gray-60);
}

.resetPasswordInfo {
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  margin-bottom: 30px;
  color: var(--gray-40);
}

.resetPasswordAction {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 30px;
  color: var(--gray-60);
}

.resetPasswordResend {
  margin-left: 4px;
  color: var(--green-60);
  cursor: pointer;
}

.resetPasswordInput {
  width: 100%;
  height: 48px;
  padding-left: 14px;
  padding-right: 7px;
}

.resetPasswordSeparatorLine {
  width: 100%;
  height: 1px;
  background-color: var(--green-20);
  margin-bottom: 30px;
}

.resetPasswordButton {
  width: 100%;
  border: none;
}

.resetPasswordButton:focus-visible {
  border: 4px solid var(--green-60);
  outline: unset !important;
}

.resetPasswordButtonSpinner {
  position: absolute !important;
  font-size: 16px !important;
  top: 3px;
  left: 129px;
  color: var(--white);
}

.resetPasswordErrorMessage {
  display: block;
  width: 100%;
  margin-top: 30px;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
}

.resetPasswordGoBackButton {
  position: relative;
  width: 123px;
}

.resetPasswordGoBackButton img,
.resetPasswordGoBackButton span {
  position: absolute;
  top: 16px;
}

.resetPasswordGoBackButton img {
  left: 33px;
}

.resetPasswordGoBackButton span {
  right: 33px;
}

.resetPasswordGoBack::before {
  content: url("../../common/imgs/aptimyz-left-arrow-icon.png");
  position: absolute;
  top: 16px;
  left: 33px;
}

@media screen and (max-width: 420px) {
  .resetPasswordButtonSpinner {
    left: 80px;
  }
}
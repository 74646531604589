.resetPasswordSubmitContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow-y: auto;
}

.resetPasswordSubmitPrimaryTitle {
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  margin-top: calc((100vh - 686px) / 2);
  margin-bottom: 30px;
  color: var(--green-60);
}

.resetPasswordSubmitSecondaryTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 30px;
  color: var(--gray-60);
}

.resetPasswordSubmitAction {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 30px;
  color: var(--gray-60);
}

.resetPasswordSubmitSeparatorLine {
  width: 100%;
  height: 1px;
  background-color: var(--green-20);
  margin-bottom: 30px;
}

.resetPasswordSubmitInput {
  width: 100%;
  height: 48px;
  padding-left: 14px;
  padding-right: 7px;
}

.resetPasswordSubmitButton {
  width: 100%;
  border: none;
}

.resetPasswordSubmitButton:focus-visible {
  border: 4px solid var(--green-60);
  outline: unset !important;
}

.resetPasswordSubmitButtonSpinner {
  position: absolute !important;
  font-size: 16px !important;
  top: 3px;
  left: 138px;
  color: var(--white);
}

.resetPasswordSubmitErrorMessage {
  display: block;
  width: 100%;
  margin-top: 30px;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
}

.resetPasswordSubmitGoBackButton {
  position: relative;
  width: 123px;
}

.resetPasswordSubmitGoBackButton img,
.resetPasswordSubmitGoBackButton span {
  position: absolute;
  top: 16px;
}

.resetPasswordSubmitGoBackButton img {
  left: 33px;
}

.resetPasswordSubmitGoBackButton span {
  right: 33px;
}

@media screen and (max-width: 420px) {
  .resetPasswordSubmitButtonSpinner {
    left: 90px;
  }
}
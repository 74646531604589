.emailVerificationCompleteContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow-y: auto;
}

.emailVerificationCompleteSection {
  width: 397px;
  height: fit-content;
}

.emailVerificationCompletePrimaryTitle {
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  margin-top: calc((100vh - 249px) / 2);
  margin-bottom: 15px;
  color: var(--green-60);
}

.emailVerificationCompleteSecondaryTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 30px;
  color: var(--gray-60);
}

.emailVerificationCompleteButton {
  width: 100%;
  border: none;
  margin-bottom: 30px;
}

.emailVerificationCompleteButton:focus-visible {
  border: 4px solid var(--green-60);
  outline: unset !important;
}

.emailVerificationCompleteButton:focus-visible {
  border: 4px solid var(--green-60);
  outline: unset !important;
}

.emailVerificationCompleteEmailContainer {
  width: 100%;
  min-height: 48px;
  padding: 0 7px 0 14px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--gray-60);
  background: var(--green-10);
  border: 1px solid var(--green-60);
  border-radius: 10px;
  box-sizing: border-box;
}

@media screen and (max-width: 420px) {
  .emailVerificationCompleteSection {
    width: 300px;
  }
}